import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../layout";
import UserInfo from "../components/userinfo/userinfo";
import PostTags from "../components/posttags/posttags";
import SEO from "../components/seo/seo";
import HTMLContent from "../components/htmlcontent";
import Comments from "../components/utterances/utterances";
import config from "../../data/siteconfig";
import moment from "moment";
import "../style/code.sass";

export default class WordsTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }

    return (
      <Layout title={post.title}>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <div>
          <div>
            <div>
              {post.cover && (
                <GatsbyImage
                  image={data.file.childImageSharp.gatsbyImageData}
                  alt="cover image"
                />
              )}
            </div>
            <h1>{post.title}</h1>
            <p>{moment(post.date).format("MMMM DD, YYYY")}</p>
            <HTMLContent
              html={postNode.html}
              style={{ marginBottom: "50px" }}
            />
            <PostTags tags={post.tags} />
            <UserInfo config={config} />
          </div>
          <Comments/>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query WordsTemplateQuery($slug: String!, $cover: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        tags
      }
      fields {
        slug
        date
      }
    }
    file(name: { eq: $cover }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
